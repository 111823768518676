<template>
  <div class="log-reg-section">
    <div class="register-inner">
      <register />
    </div>
  </div>
</template>
<script>
import register from "@/components/auth/register.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    register,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "systemCustomerServiceUrl", // 客服
    ]),
  },
  mounted() {},
  methods: {
    tabChange(val) {
      this.tabIndex = val;
    },
  },
};
</script>
<style lang="less" scoped>
.log-reg-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url(../../assets/images/km/login-page-bk.png) no-repeat center top /
    100% auto;
  padding-bottom: 25px;
  box-sizing: border-box;
  .register-inner {
    margin-top: 45px;
    width: 485px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>